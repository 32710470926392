export default function AlertBox({
  text = "Problème de connexion",
  show = true,
}: {
  text?: string;
  show?: boolean;
}) {
  return (
    <div
      className={
        "text-white bg-red-400 text-center absolute top-3 right-3 w-48 p-3 rounded-3xl transition-opacity " +
        (show ? "opacity-100" : "opacity-0")
      }
    >
      {text}
    </div>
  );
}
