import React, { useEffect, useRef } from "react";
import { Session } from "@supabase/supabase-js";
import Layout from "./components/Layout";
import AlertBox from "./components/AlertBox";

interface Message {
  author: string;
  text: string;
  type: MessageType;
}

enum MessageType {
  USER = "USER",
  BOT = "BOT",
}

interface WSMsg {
  content: string;
  type: WSMsgType;
}

enum WSMsgType {
  AUTH = "auth",
  QUESTION = "question",
}

interface WSData {
  message: string;
  type: WSDataType;
}

enum WSDataType {
  START = "start",
  STREAM = "stream",
  END = "end",
}

const wsUrl = process.env.REACT_APP_WS_URL || "ws://localhost:8000/api/chat/";

const MessageBox = ({ author, text, type }: Message) => {
  const classes =
    type === MessageType.BOT
      ? "rounded-r-3xl rounded-tl-3xl"
      : "rounded-l-3xl rounded-tr-3xl self-end";
  return (
    <div
      className={
        "px-5 py-3 md:text-xl text-black bg-white w-4/5 mx-3 my-2 first:mb-auto " +
        classes
      }
    >
      <div>
        <span className="font-bold">
          {type === MessageType.BOT ? "Plum" : "Moi"} :
        </span>
        <span className="ml-2">{text}</span>
      </div>
    </div>
  );
};

export default function Chat({ session }: { session: Session }) {
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [input, setInput] = React.useState<string>("");
  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = (event) => {
      const loginMsg: WSMsg = {
        content: session.access_token,
        type: WSMsgType.AUTH,
      };
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify(loginMsg));
      }
      console.log("Connected to server");
      setShowAlert(false);
    };

    ws.current.onmessage = (event) => {
      const data: WSData = JSON.parse(event.data);
      if (data.type === WSDataType.START) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            author: "Bot",
            text: "",
            type: MessageType.BOT,
          },
        ]);
      } else if (data.type === WSDataType.STREAM) {
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              text: lastMessage.text + data.message,
            },
          ];
        });
      }
    };

    ws.current.onclose = (event) => {
      setShowAlert(true);
      console.log("Disconnected from server");
    };

    ws.current.onerror = (event) => {
      setShowAlert(true);
      console.error(event);
    };

    return () => {
      if (ws.current !== null) {
        ws.current.close();
      }
    };
  }, [session.access_token]);

  useEffect(() => {
    console.log("Loaded");
  }, []);

  const sendMessage = () => {
    if (input === "") return;

    setInput("");
    setMessages((prevMessages) => [
      ...prevMessages,
      { author: "Moi", text: input, type: MessageType.USER },
    ]);

    if (ws.current?.readyState === WebSocket.OPEN) {
      const questionMsg: WSMsg = {
        content: input,
        type: WSMsgType.QUESTION,
      };
      ws.current.send(JSON.stringify(questionMsg));
    }
  };

  return (
    <Layout className="h-screen">
      <AlertBox show={showAlert} />
      <div className="flex-1 flex flex-col overflow-scroll max-w-xl">
        <div className="flex-1 w-full text-white overflow-scroll flex flex-col-reverse">
          {messages
            .slice()
            .reverse()
            .map((m, idx) => (
              <MessageBox key={idx} {...m} />
            ))}
          <MessageBox
            author="Bot"
            text="Bonjour, je suis Plum, un assitant IA créé pour répondre à des questions d'intimité de manière anonyme, comment puis-je vous aider aujourd'hui ?"
            type={MessageType.BOT}
          />
        </div>
        <div className="flex items-center py-3 px-2">
          <input
            type="text"
            className="w-full px-5 py-3 rounded-l-3xl md:text-xl"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
            maxLength={280}
          />
          <button
            className="bg-secondary text-black font-bold px-8 py-3 rounded-r-3xl shrink-0 md:text-xl"
            onClick={sendMessage}
          >
            Envoyer
          </button>
        </div>
      </div>
    </Layout>
  );
}
