import { useQuery } from "react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { getArticles } from "./api";
import { useState } from "react";
import Table from "./components/Table";
import { Article } from "./types";
import Layout from "./components/Layout";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<Article>();

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const stringToColour = function (str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    const toAdd = "00" + value.toString(16);
    colour += toAdd.substring(toAdd.length - 2);
  }
  const rgb = hexToRgb(colour);

  if (rgb === null) {
    return "lightgray";
  }

  const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`;
  return rgba;
};

const Tag = ({ tag }: { tag: string }) => (
  <span
    className="rounded-full px-2 py-1 text-xs font-bold mr-1"
    style={{
      backgroundColor: stringToColour(tag),
    }}
  >
    {tag}
  </span>
);

const SearchBar = ({ onSearch }: { onSearch: (s: string) => void }) => {
  const [search, setSearch] = useState("");

  return (
    <div className="flex items-center pb-4">
      <input
        className="border shadow rounded-xl px-5 py-2 mr-2"
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button
        onClick={() => onSearch(search)}
        className="bg-secondary text-black font-bold px-8 py-2 rounded-xl border shadow shrink-0"
      >
        Search
      </button>
    </div>
  );
};

const defaultColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (info) => <div className="text-right">{info.getValue()}</div>,
  }),
  columnHelper.accessor("title", {
    header: () => "Title",
    cell: (info) => (
      <Link to={"/content/" + info.row.getValue("id")}>
        <span className="font-bold hover:underline">{info.getValue()}</span>
      </Link>
    ),
  }),
  columnHelper.accessor("tags", {
    header: () => "Tags",
    cell: (info) =>
      info.getValue().map((tag) => <Tag key={tag.toLowerCase()} tag={tag} />),
  }),
];

const emptyData: Article[] = [];

export default function Content() {
  const [searchText, setSearchText] = useState<string | null>(null);

  const query = useQuery<Article[]>({
    queryKey: ["articles", { searchText }],
    queryFn: () => getArticles(searchText),
    keepPreviousData: true, // so that previous data is shown while loading
  });

  const table = useReactTable({
    data: query.data ?? emptyData,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Layout>
      <div className="mx-5 mb-5 p-5 rounded-3xl bg-white border shadow">
        <h2 className="text-3xl font-bold pb-4">Articles</h2>
        <SearchBar onSearch={setSearchText} />
        <Table table={table} />
      </div>
    </Layout>
  );
}
