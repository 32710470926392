import { useState, useEffect } from "react";
import { Session } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import Chat from "./Chat";
import Layout from "./components/Layout";
import Dashboard from "./Dashboard";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Content from "./Content";
import ContentDetail from "./ContentDetail";

import supabase from "./config/supabase";

export default function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <Layout>
        <div className="mx-auto px-3 w-full max-w-lg">
          <div className="py-3 px-10 bg-white rounded-3xl w-full">
            <Auth
              supabaseClient={supabase}
              appearance={{
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: "#FFCFEA",
                      brandAccent: "#ffb3de", // a bit darker
                      brandButtonText: "black",
                    },
                    fonts: {
                      bodyFontFamily: "Nunito",
                      buttonFontFamily: "Nunito",
                      inputFontFamily: "Nunito",
                      labelFontFamily: "Nunito",
                    },
                  },
                },
              }}
              providers={[]}
            />
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Chat session={session} />} />
          <Route path="/dashboard" element={<Dashboard session={session} />} />
          <Route path="/content" element={<Content />} />
          <Route path="/content/:articleId" element={<ContentDetail />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
