import { useMutation, useQuery } from "react-query";
import { getArticle, updateArticle } from "./api";
import Layout from "./components/Layout";
import { Article } from "./types";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function ContentDetail() {
  const { articleId } = useParams();
  const [data, setData] = useState({
    title: "",
    content: "",
  });

  const { data: article, refetch } = useQuery<Article>({
    queryKey: ["article", articleId],
    queryFn: () => getArticle(articleId),
    enabled: !!articleId,
  });

  useEffect(() => {
    if (article) {
      setData({
        title: article.title,
        content: article.content,
      });
    }
  }, [article]);

  const mutation = useMutation({
    mutationFn: updateArticle,
    onSettled: () => {
      refetch();
    },
  });

  return (
    <Layout>
      <div className="w-full px-5 pb-5">
        <div className="p-5 rounded-3xl bg-white border shadow">
          <h2 className="text-3xl font-bold pb-4">Article</h2>
          <input
            type="text"
            placeholder="Title"
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
            className="rounded-xl p-3 border shadow mb-4"
          />
          <textarea
            placeholder="Content"
            className="w-full border shadow rounded-xl p-3 mb-4 h-96 "
            value={data.content}
            onChange={(e) => setData({ ...data, content: e.target.value })}
          />
          <button
            onClick={() =>
              article
                ? mutation.mutate({
                    id: article.id,
                    title: data.title,
                    content: data.content,
                  })
                : null
            }
            className="bg-secondary text-black font-bold px-8 py-2 rounded-xl border shadow shrink-0"
          >
            Save
          </button>
        </div>
      </div>
    </Layout>
  );
}
