import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { Line, Bar } from "react-chartjs-2";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";

import Layout from "./components/Layout";
import { getStats } from "./api";

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = "Nunito";

interface Stats {
  queries: QueryLog[];
  costs: CostLog[];
  tags: TagLog[];
  articles: ArticleLog[];
}

interface QueryLog {
  date: string;
  count: number;
}

interface CostLog {
  date: string;
  cost: number;
}

interface TagLog {
  tag: string;
  count: number;
}

interface ArticleLog {
  article: string;
  count: number;
}

export default function Dashboard({ session }: { session: Session }) {
  const { data } = useQuery<Stats>({
    queryKey: "stats",
    queryFn: () => getStats(session.access_token),
  });

  return (
    <Layout>
      <div className="w-full px-5 pb-5">
        <div className="p-5 rounded-3xl bg-white border shadow">
          <h1 className="text-3xl font-bold pb-4">Dashboard</h1>
          <div className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid gap-3">
            <div className="p-3 bg-white rounded-3xl shadow border">
              <Line
                height={300}
                data={{
                  labels: data?.queries.map((d) => d.date),
                  datasets: [
                    {
                      label: "Number of queries",
                      data: data?.queries.map((d) => d.count),
                      borderColor: "#FFCFEA",
                      backgroundColor: "#FFCFEA",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Number of queries",
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      type: "time",
                      time: {
                        unit: "day",
                        tooltipFormat: "MMM D",
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      ticks: {
                        callback: (tickValue, index, ticks) => {
                          if (
                            typeof tickValue === "number" &&
                            tickValue % 1 === 0
                          ) {
                            return tickValue;
                          }
                        },
                      },
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </div>
            <div className="p-3 bg-white rounded-3xl shadow border">
              <Line
                height={300}
                data={{
                  labels: data?.costs.map((d) => d.date),
                  datasets: [
                    {
                      label: "Cost",
                      data: data?.costs.map((d) => d.cost),
                      borderColor: "#FFCFEA",
                      backgroundColor: "#FFCFEA",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Cost",
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      type: "time",
                      time: {
                        unit: "day",
                        tooltipFormat: "MMM D",
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </div>
            <div className="p-3 bg-white rounded-3xl shadow border">
              <Bar
                height={300}
                data={{
                  labels: data?.tags.map((d) => d.tag),
                  datasets: [
                    {
                      label: "Tag count",
                      data: data?.tags.map((d) => d.count),
                      borderColor: "#FFCFEA",
                      backgroundColor: "#FFCFEA",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Tag count",
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      ticks: {
                        minRotation: 45,
                        maxRotation: 45,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>

            <div className="p-3 bg-white rounded-3xl shadow border">
              <Bar
                height={300}
                data={{
                  labels: data?.articles.map((d) => d.article),
                  datasets: [
                    {
                      label: "Article count",
                      data: data?.articles.map((d) => d.count),
                      borderColor: "#FFCFEA",
                      backgroundColor: "#FFCFEA",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Article count",
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                      ticks: {
                        minRotation: 90,
                        maxRotation: 90,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
