import { createClient } from "@supabase/supabase-js";

if (!process.env.REACT_APP_SB_URL || !process.env.REACT_APP_SB_KEY) {
  throw new Error("Missing Supabase URL or Key");
}

const supabase = createClient(
  process.env.REACT_APP_SB_URL,
  process.env.REACT_APP_SB_KEY
);

export default supabase;
