import axios, { InternalAxiosRequestConfig } from "axios";
import supabase from "../config/supabase";

const auth = async (req: InternalAxiosRequestConfig) => {
  const {
    data: { session },
  } = await supabase.auth.getSession();

  if (!session) {
    throw new Error("No active session");
  }

  req.headers.authorization = "Bearer " + session.access_token;

  return req;
};

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000/api",
});
authApi.interceptors.request.use(auth);

const getArticles = async (searchText: string | null) => {
  let res = null;
  if (!!searchText) {
    res = await authApi.post("/articles/query", {
      content: searchText,
    });
  } else {
    res = await authApi.get("/articles/");
  }

  return res.data;
};

const getArticle = async (id: string | undefined) => {
  if (!id) {
    throw new Error("Article id is not provided");
  }
  const res = await authApi.get(`/articles/${id}`);
  return res.data;
};

const updateArticle = async ({
  id,
  title,
  content,
}: {
  id: number;
  title: string;
  content: string;
}) => {
  await authApi.put(`/articles/${id}`, {
    title,
    content,
  });
};

const getStats = async (accessToken: string) => {
  const res = await authApi.get("/stats/", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data;
};

export { getArticles, getArticle, updateArticle, getStats };
