import React from "react";
import Header from "./Header";

export default function Layout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={"flex flex-col bg-primary min-h-screen " + className}>
      <Header />
      <main className="flex flex-1 flex-col items-center overflow-scroll">
        {children}
      </main>
    </div>
  );
}
